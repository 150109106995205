import { TextField, styled as styledMui, Button, Typography, MenuList, MenuItem } from '@mui/material';
import styled from '@emotion/styled';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/const';
import { theme } from 'src/theme';
const TextFieldStyled = styled(TextField)(() => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    height: '100%',
    marginBottom: '24px',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[100]}`,
      '& .MuiInputBase-root': {
        background: theme.palette.grey[100],
      },
    },
  },
  textarea: {
    height: '100% !important',
  },
  '& .MuiInputBase-root': {
    height: 119,
  },
  fieldset: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  '&:hover': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
    },
  },
  [`@media (max-width: ${MAX_TABLET_WIDTH}px)`]: {
    '& .MuiInputBase-root': {
      height: 'auto',
    },
  },
}));

const TextFieldTablet = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    minHeight: 42,
    padding: '16px',
  },
}));

const ButtonStyled = styledMui(Button)(() => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  '& > :first-of-type': {
    margin: 0,
  },
}));

const TypographyStyled = styledMui(Typography)(() => ({
  display: 'inline',
  marginBottom: '8px',
}));

const ButtonHashtag = styledMui(Button)(() => ({
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: '8px',
  height: 'auto',
  minWidth: 0,
}));

const SendMessageWrapper = styled.div`
  padding: 28px 36px;
  position: relative;
  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding: 24px;
  }
  @media (max-width: ${MAX_MOBILE_WIDTH}px) {
    padding: 6px 12px 16px;
  }
`;

const Dropdown = styledMui(MenuList)(() => ({
  width: '233px',
  position: 'absolute',
  left: '36px',
  bottom: '148px',
  background: '#FFFFFF',
  padding: 0,
  filter: 'drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15))',
  zIndex: 1,
}));

const DropdownItem = styledMui(MenuItem)(() => ({
  height: '40px',
  padding: '10px 16px',
  cursor: 'pointer',
  color: theme.palette.grey[200],
  fontSize: '14px',
  '&:hover': {
    color: theme.palette.primary.main,
    background: '#EEF5F8',
  },
}));

export {
  TextFieldStyled,
  TextFieldTablet,
  ButtonStyled,
  TypographyStyled,
  ButtonHashtag,
  SendMessageWrapper,
  Dropdown,
  DropdownItem,
};
